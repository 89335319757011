import { data } from "./data.js";
import { favicon, favicon_size } from "./data.js";

const form = document.getElementById("formulario");
const lista = document.getElementById("lista");
const error = document.getElementById("error");

let map;
let markers = [];

async function initMap() {
  map = new google.maps.Map(document.getElementById("map"), {
    center: { lat: 40.4556127, lng: -3.7058092 },
    zoom: 7,
    gestureHandling: "greedy",
  });

  if (!navigator.geolocation) {
    console.log("Error: Falló el servicio de geolocalización.");
    return;
  }

  navigator.geolocation.getCurrentPosition(async (position) => {
    const userLocation = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      z: 12,
    };
    map.setCenter(userLocation);
    map.setZoom(13);

    const filteredPoints = getFilteredPoints(
      userLocation.lat,
      userLocation.lng
    );
    displayHtmlList(filteredPoints.slice(0, 4), lista);
  });

  displayPoints(data);
}

function getFilteredPoints(lat, lng) {
  function haversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Earth's radius in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  }

  const pointsWithDistances = data.map((point) => ({
    ...point,
    distance: haversineDistance(lat, lng, point.lat, point.long),
  }));

  return pointsWithDistances
    .sort((a, b) => a.distance - b.distance)
    .slice(0, 10);
}

function getLocationFromText(input) {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: input }, (results, status) => {
      if (status === "OK" && results[0]) {
        const { lat, lng } = results[0].geometry.location;
        resolve({ lat: lat(), lng: lng() });
      } else {
        reject("Geocoding failed: " + status);
      }
    });
  });
}

async function submitForm(e) {
  e.preventDefault();

  error.innerHTML = "";
  const formData = new FormData(form);
  const location = formData.get("location");

  if (!location) return;

  try {
    const coordinates = await getLocationFromText(location);
    const filteredPoints = getFilteredPoints(coordinates.lat, coordinates.lng);

    if (filteredPoints.length > 0) {
      map.setCenter(coordinates);
      map.setZoom(13);
      displayHtmlList(filteredPoints.slice(0, 5), lista);
    } else {
      error.innerHTML = `
        <div class="flex flex-row justify-center items-center gap-3">
          <h1 class="text-center font-semibold text-xl">
            No se encontraron resultados para esta ubicación
            <i class="bi bi-compass text-lg"></i>
          </h1>
        </div>
      `;
    }

    displayPoints(data);
  } catch (err) {
    error.innerHTML = `
      <div class="flex flex-row justify-center items-center gap-3">
        <h1 class="text-center font-semibold text-xl">
          No se pudo encontrar la ubicación
          <i class="bi bi-compass text-lg"></i>
        </h1>
      </div>
    `;
    console.error(err);
  }
}

function displayHtmlList(points, domElement) {
  domElement.innerHTML = "";
  points.forEach((point) => {
    domElement.innerHTML += `
                    <a href="https://www.google.com/maps/search/?api=1&query=${point.lat},${point.long}">
                  <div class=" text-black hover:scale-105 transition-all cursor-pointer border-2  ">
                          <div class="flex flex-row justify-start items-center gap-3 px-4 py-3">
                              <i class="bi bi-geo-alt text-2xl"></i>
                              <h1 class="font-bold text-xl ">
                                  ${point["NOMBRE TPV"]}</h1>
                          </div>
                          <div class="border border-b"></div>
                          <div class="px-4 py-5">
                              <div class="flex flex-row justify-start items-center gap-3">
  
                                  <i class="bi bi-signpost"></i>
                                  <p>${point.DOMICILIO}</p>
                              </div>
                              <div class="flex flex-row justify-start items-center gap-3">
                                  <i class="bi bi-map"></i>
                                  <p>${point.PROVINCIA}</p>
                              </div>
                              <div class="flex flex-row justify-start items-center gap-3">
                                  <i class="bi bi-mailbox-flag"></i>
                                  <p>${point.CP}</p>
                              </div>
  
                          </div>
                      </div>
              </a>
          `;
  });
}
function displayPoints(points) {
  const infoWindow = new google.maps.InfoWindow();

  points.forEach((point) => {
    const marker = new google.maps.Marker({
      position: { lat: point.lat, lng: point.long },
      map: map,
      title: point.NOMBRE,
      icon: {
        url: favicon[point.BANDERA],
        scaledSize: new google.maps.Size(
          favicon_size[point.BANDERA].width * 0.6,
          favicon_size[point.BANDERA].height * 0.6
        ),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(10, 20),
        labelOrigin: new google.maps.Point(10, 10),
      },
    });

    marker.addListener("click", () => {
      infoWindow.setContent(
        `<strong>${point["BANDERA"]} ${point["NOMBRE TPV"]}</strong><p>${point.DOMICILIO}</p>`
      );
      infoWindow.open(map, marker);
    });

    markers.push(marker);
  });
}

document.addEventListener("DOMContentLoaded", async () => {
  await initMap();
  form.addEventListener("submit", submitForm);
});
